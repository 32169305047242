import { fetcher } from "fetcher!sofe";
import { useEffect, useState } from "react";
import { constants } from "canopy-styleguide!sofe";
import { genQueryKey, useQuery } from "src/react-query";

export const useGetThumbnail = (initialFile?: any) => {
  const [file, setFile] = useState<any>(initialFile || null);
  const [thumbnailCanvas, setThumbnailCanvas] = useState<HTMLCanvasElement | null>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { data } = useQuery({
    queryKey: genQueryKey("file-thumbnail", { fileId: file?.id }),
    queryFn: () => fetcher(`/api/docs/files/${file?.id}?includes=preview_links`).then((res: any) => res.file),
    enabled: !!file?.id,
    staleTime: 1000 * 60 * 5, //5 minutes
  });

  useEffect(() => {
    //find if the script is already loaded
    const pdfTronCore = document.getElementById("pdfTronCore");
    if (pdfTronCore) return;
    //if not, load the script
    const pdfScript = document.createElement("script");
    pdfScript.src = "/PDFTron-upgrade/webviewer/core/webviewer-core.min.js";
    pdfScript.id = "pdfTronCore";
    pdfScript.type = "text/javascript";
    pdfScript.onload = () => setScriptLoaded(true);
    document.body.appendChild(pdfScript);
  }, []);

  //write a timer that checks for window.Core
  useEffect(() => {
    if (window.Core) return setScriptLoaded(true);
    //this interval should only run in the case where
    //the hook is called multiple times in a short period of time
    //and the script is not loaded already
    //ie .map of files that are being rendered in a list
    const interval = setInterval(() => {
      if (window.Core) {
        setScriptLoaded(true);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!data || !scriptLoaded) return;
    const getThumbnail = async () => {
      const Core = window.Core;
      Core.setWorkerPath("/PDFTron-upgrade/webviewer/core");

      const doc = await Core.createDocument(data?.preview_link, {
        licenseKey: constants.pdfTronLicenseKey,
        loadAsPDF: true,
      });
      doc.loadCanvas({
        pageNumber: 1,
        drawComplete: async (thumbnail: HTMLCanvasElement) => {
          setThumbnailCanvas(thumbnail);
          doc.unloadResources();
        },
      });
    };
    getThumbnail();
  }, [data, scriptLoaded]);

  return { fileImage: thumbnailCanvas?.toDataURL(), setFileImage: setFile };
};
